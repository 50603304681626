import axios from "axios";
import {creds} from "@/states/Cred";

export default class GoogleTranslateService {
    translate(comment, fromLang, toLang = 'en') {
        const info = {
            comment: comment,
            fromTo: {source: fromLang, target: toLang}
        };
        return axios.post(`${process.env.VUE_APP_API_URL}/translate`, info,
            {headers: {"Authorization": `Bearer ${creds.token}`}})
            .then(response => {
            return response.data.translatedText;
        }).catch(err => {
            console.error(err);
        });
    }
}
