<template>
    <Toast/>
    <div class="floatlabel-demo">
        <div class="card">
            <div class="grid p-fluid mt-3">
                <div class="field md:col-3">
                  <span class="p-float-label">
                    <Chips id="keyword" v-model="lastSearchCriteria.filter.keywords"/>
                      <!--            <AutoComplete id="keyword" v-model="keyword" :suggestions="filteredCountries" @complete="searchCountry($event)" field="name"></AutoComplete>-->
                    <label for="keyword">Keyword</label>
                  </span>
                </div>
                <div class="field md:col-4 text-center">
                    <div class="grid p-fluid">
                        <div class="field md:col-4 text-center">
                            <div style="height:15px;">Rating</div>
                            <Rating inputId="rating" v-model="lastSearchCriteria.filter.rating"/>

                        </div>
                        <div class="field md:col-4 text-center">
                            <div style="height:15px;">Rating2</div>
                            <Rating inputId="rating2" v-model="lastSearchCriteria.filter.rating2"/>
                        </div>
                        <div class="field md:col-3 ">
                            <div style="height:10px;"></div>
                            <div class="field-checkbox">
                                <Checkbox id="answered" name="answered" value="Answered" :binary="true"
                                          v-model="lastSearchCriteria.filter.answered"/>
                                <label for="answered">Answered</label>
                            </div>
                        </div>
                        <div class="field md:col-1 text-center">
                            <span>
                            <Button style="width: auto;" type="button" label="Search" icon="pi pi-search"
                                    @click="load(0)"/>
                              </span>
                        </div>
                    </div>
                </div>
                <!--				<div class="field col-12 md:col-4">-->
                <!--					<span class="p-float-label">-->
                <!--						<Calendar inputId="calendar" v-model="calendarValue"></Calendar>-->
                <!--						<label for="calendar">Calendar</label>-->
                <!--					</span>-->
                <!--				</div>-->


                <!--        <div class="field col-12 md:col-4"></div>-->

            </div>
            <div>
                <Button icon="pi pi-check" :label="selectedReviewsCount ? 'Uncheck all' : 'Check all'"
                        :badge="selectedReviewsCount.toString()" class="p-button-warning mr-2 mb-2"
                        @click="checkall()"></Button>
                <Button icon="pi pi-plus" label="Expand All" @click="expandAll" class="mr-2 mb-2"/>
                <Button icon="pi pi-minus" label="Collapse All" @click="collapseAll" class="mr-2 mb-2"/>
                <Button icon="pi pi-pencil" label="Comment" :disabled="!selectedReviewsCount"
                        @click="openDialog"
                        class="p-button-rounded p-button-info mr-2 mb-2"/>
                <Button icon="pi pi-book" label="Template Comment" :disabled="!selectedReviewsCount"
                        @click="openTemplateCommentDialog" class="p-button-rounded mr-2 mb-2"/>
                <Dialog class="p-fluid formgrid grid col-12" header="Template Comment"
                        v-model:visible="displayTemplateCommentDialog" :modal="true"
                        :style="{'max-width': '600px'}">
                    <div class="grid p-fluid">
                        <div class="col-12 md:col-12 mt-4">
                            <Dropdown v-model="selectedTemplate" :options="templates"
                                      optionLabel="templateName"
                                      placeholder="Select a template"/>
                        </div>
                        <div class="col-12 md:col-12 mt-4">
                      <Textarea v-model="selectedTemplate.templateContent" disabled style="opacity: 1" rows="5"
                                cols="30"/>
                        </div>

                        <div class="col-6 mt-4 text-left">
                            <Checkbox id="translate" name="translate" :binary="true"
                                      v-model="translate"/>
                            <label for="translate"> Translate</label>
                        </div>

                        <div class="col-3 mt-2">
                            <Button label="Dismiss" @click="closeTemplateCommentDialog"
                                    class="p-button-secondary p-button-rounded"/>
                        </div>
                        <div class="col-3 mt-2">
                            <Button label="Send" @click="sendTemplateComment" icon="pi pi-check"
                                    class="p-button-primary p-button-rounded"/>
                        </div>

                    </div>
                </Dialog>
                <Dialog class="p-fluid formgrid grid" header="Comment" v-model:visible="display"
                        :breakpoints="{'960px': '75vw'}"
                        :style="{width: '70%', 'min-width': '400px', 'max-width': '800px' }"
                        :modal="true">
                    <div class="p-fluid formgrid grid">
                        <div class="field col-6 text-left">
                            <Checkbox id="translate" name="translate" :binary="true"
                                      v-model="translate"/>
                            <label for="translate">Translate</label>
                        </div>
                        <div class="field col-6 text-right">
                            Chars: {{ comment.length }}
                            <span v-show="(comment.length > 240) && (comment.length < 320)"> 👀</span>
                            <span v-show="(comment.length >= 320) && (comment.length <= 350)"> 😵‍💫</span>
                            <span v-show="comment.length > 350"> 🦹🏼‍🗯 3️⃣5️⃣0️⃣</span>
                        </div>
                        <div class="col-12">
                            <Textarea id="comment" rows="5" v-model="comment"/>
                        </div>
                    </div>
                    <template #footer>
                        <Button label="Dismiss" @click="closeDialog"
                                class="p-button-secondary p-button-rounded"/>
                        <Button label="Send" @click="sendComment" icon="pi pi-check"
                                class="p-button-primary p-button-rounded"/>
                    </template>
                </Dialog>
            </div>
        </div>
    </div>
    <div class="grid reviews">
        <div class="col-12">
            <div class="scard">
                <DataTable :value="filteredReviews" v-model:expandedRows="expandedRows" dataKey="reviewId"
                           responsiveLayout="scroll">
                    <Column :expander="true" headerStyle="width: 2rem"/>
                    <Column field="selected" header=" " :sortable="false">
                        <template #body="slotProps">
                            <Checkbox v-model="slotProps.data.selected" @change="updateSelectedReviewsCount"
                                      :binary="true"/>
                        </template>
                    </Column>
                    <Column field="comments[0].userComment.text" header="Review" :sortable="false">
                        <template #body="slotProps">
              <Textarea disabled style="opacity: 1" :autoResize="true" rows="4" cols="50"
                        v-model="slotProps.data.comments[0].userComment.text"/>
                        </template>
                    </Column>
                    <Column field="authorName" header="Author" :sortable="true">
                        <template #body="slotProps">
                            {{ slotProps.data.authorName }}
                        </template>
                    </Column>
                    <Column field="reviewerLang" header="Language" :sortable="true">
                        <template #body="slotProps">
                            {{ slotProps.data.reviewerLang }}
                        </template>
                    </Column>
                    <Column field="rating" header="Rating" :sortable="true">
                        <template #body="slotProps">
                            <Rating :modelValue="slotProps.data.rating" :readonly="true" :cancel="false"/>
                        </template>
                    </Column>
                    <Column field="date" header="Date" :sortable="true">
                        <template #body="slotProps">
                            {{ formatDate(slotProps.data.date) }}
                        </template>
                    </Column>
                    <Column field="answered" header="Answered?" :sortable="true">
                        <template #body="slotProps">
                            <Avatar v-if="slotProps.data.comments[1]" icon="pi pi-check" class="mr-2 p-avatar-success"
                                    size="normal"
                                    shape="circle"></Avatar>
                        </template>
                    </Column>
                    <template #expansion="slotProps">
                        <div>
                            <div class="soluk word-break">
                                Original Text:
                                <span class="normalText">
                  {{ slotProps.data.comments[0].userComment.originalText }}
                </span>
                            </div>
                            <div class="soluk word-break">
                                Developer Comment:
                                <span class="normalText">
                  {{ slotProps.data.comments[1]?.developerComment?.text }}
                </span>
                            </div>
                        </div>
                    </template>

                </DataTable>
            </div>
        </div>
    </div>
</template>

<script>
import PlaystoreReviewService from "@/service/PlaystoreReviewService";
import {lastSearchCriteria, playstoreReviewList, reviewListState} from '@/states/State';
import PlaystoreAppService from "@/service/PlaystoreAppsService";
import GoogleTranslateService from "@/service/GoogleTranslateService";
import TemplateService from "@/service/TemplateService";


export default {
    emits: ['acall'],
    async created() {
        this.playstoreReviewService = new PlaystoreReviewService();
        this.playstoreAppService = new PlaystoreAppService();
        this.googleTranslateService = new GoogleTranslateService();
        this.templateService = new TemplateService();
        await this.playstoreReviewService.getReviews(0, 100);
        this.filterReviews();
        this.selectedReviewsCount = this.getSelectedReviewsCount()

    },
    watch: {
        'lastSearchCriteria.app': function () {
            this.playstoreReviewService.getReviews(0, 100);
            this.filterReviews();
            this.selectedReviewsCount = this.getSelectedReviewsCount()
        }
    },
    productService: null,
    data() {
        return {
            lastSearchCriteria,
            playstoreReviewList,
            reviewListState,
            loading: [false, false, false],
            display: false,
            displayTemplateCommentDialog: false,
            comment: "",
            selectedReviewsCount: 0,
            reviews: [],
            translate: false,
            expandedRows: [],
            templates: [],
            filteredReviews: this.filteredReviews,
            selectedTemplate:
                {
                    templateContent: ""
                },
        }
    },
    mounted() {
        this.templateService.getTemplatesByAccount(lastSearchCriteria.account._id)
            .then(res => {
                if (res.status !== 200) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Error Occurred while Fetching templates',
                        detail: res.status + " " + res.data.detail,
                        life: 3000
                    });
                } else {
                    this.templates = res.data
                }
            })
    },
    methods: {
        filterReviews() {
            const filtered = this.playstoreReviewList.list.reviews.filter(review => {
                let hasWord = true;
                if (lastSearchCriteria.filter.keywords.length != 0) {
                    hasWord = lastSearchCriteria.filter.keywords.reduce(function (accumulator, current) {
                        return accumulator || review.comments[0].userComment.text.includes(current);
                    }, false);
                }
                let rating;
                if (lastSearchCriteria.filter.rating2 == 0) {
                    rating = lastSearchCriteria.filter.rating > 0 ? lastSearchCriteria.filter.rating == review.rating : true;
                } else {
                    rating = (review.rating <= lastSearchCriteria.filter.rating2) && (review.rating >= lastSearchCriteria.filter.rating)
                }
                if (lastSearchCriteria.filter.answered) {
                    return rating && hasWord && review.comments.length > 1;
                } else {
                    return rating && hasWord
                }
            });

            this.filteredReviews = filtered;
            return filtered;
        },
        load(index) {
            this.loading[index] = true;
            setTimeout(() => this.loading[index] = false, 1000);
            this.filterReviews();
        },
        async sendComment() {
            this.closeDialog();
            const selectedReviews = this.filteredReviews.filter(rev => {
                return rev.selected;
            });

            if (!this.translate) {
                this.sendRawComment(selectedReviews);
                return;
            }

            const langs = [...new Set(selectedReviews.map(r => r.reviewerLang.toLowerCase()))];  // get unique langs
            let convertedComments = {}; // promise array for translations
            for (const lang of langs) {
                convertedComments[lang] = this.googleTranslateService.translate(this.comment, 'en', lang); // a promise
            }
            for (const rev of selectedReviews) {
                convertedComments[rev.reviewerLang.toLowerCase()].then(convertedComment => {

                    this.sendConvertedComment(rev, convertedComment);
                }).catch(error => {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Error translating language: ' + rev.reviewerLang,
                        detail: error,
                        life: 5000
                    });
                })
            }
        },
        async sendConvertedComment(review, convertedComment) {
            try {
                await this.playstoreReviewService.sendComment(review.reviewId, convertedComment);
                review.selected = false;
                this.displayTemplateCommentDialog = false;
            } catch (error) {
                this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: 5000});
            }
        },
        async sendRawComment(selectedReviews) {
            for (const rev of selectedReviews) {
                try {
                    await this.playstoreReviewService.sendComment(rev.reviewId, this.comment);
                } catch (error) {
                    this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: 3000});
                }
            }
        },
        openDialog() {
            this.translate = false;
            this.display = true;
        },
        openTemplateCommentDialog() {
            this.displayTemplateCommentDialog = true;
        },
        async sendTemplateComment() {
            const selectedReviews = this.filteredReviews.filter(rev => {
                return rev.selected;
            });

            if (!this.translate) {
                for (let rev of selectedReviews) {
                    this.sendConvertedComment(rev, this.selectedTemplate.templateContent)
                }
                return;
            }

            const langs = [...new Set(selectedReviews.map(r => r.reviewerLang.toLowerCase()))];  // get unique langs
            let convertedComments = {}; // promise array for translations
            for (const lang of langs) {
                convertedComments[lang] = this.googleTranslateService.translate(this.selectedTemplate.templateContent, 'en', lang); // a promise
            }
            for (const rev of selectedReviews) {
                convertedComments[rev.reviewerLang.toLowerCase()].then(convertedComment => {

                    this.sendConvertedComment(rev, convertedComment);

                }).catch(error => {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Error translating language: ' + rev.reviewerLang,
                        detail: error,
                        life: 5000
                    });
                })
            }

            this.closeTemplateCommentDialog();
        },
        closeTemplateCommentDialog() {
            this.displayTemplateCommentDialog = false;
        },
        closeDialog() {
            this.display = false;
        },
        getSelectedReviewsCount() {
            return this.filteredReviews.reduce(function (accumulator, current) {
                var inc = current.selected ? 1 : 0;
                return accumulator + inc;
            }, 0);
        },
        updateSelectedReviewsCount() {
            this.selectedReviewsCount = this.getSelectedReviewsCount();
        },
        checkall() {
            var selection = !(this.selectedReviewsCount > 0);
            this.filteredReviews.forEach(review => {
                review.selected = selection;
            })
            this.updateSelectedReviewsCount()
        },
        expandAll() {
            this.expandedRows = this.filterReviews().filter(review => review.reviewId)
        },
        collapseAll() {
            this.expandedRows = null;
        },
        formatCurrency(value) {
            return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
        },
        formatDate(value) {
            return value.toLocaleDateString('en-US', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            }) + ' ' + value.getHours() + ":" + value.getMinutes();

        },
    }
}
</script>

<style scoped lang="scss">
.customer-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;

  &.status-qualified {
    background: #C8E6C9;
    color: #256029;
  }

  &.status-unqualified {
    background: #FFCDD2;
    color: #C63737;
  }

  &.status-negotiation {
    background: #FEEDAF;
    color: #8A5340;
  }

  &.status-new {
    background: #B3E5FC;
    color: #23547B;
  }

  &.status-renewal {
    background: #ECCFFF;
    color: #694382;
  }

  &.status-proposal {
    background: #FFD8B2;
    color: #805B36;
  }
}

.answer-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;

  &.status-answered {
    background: #C8E6C9;
    color: #256029;
  }

  &.status-unanswered {
    background: #FFCDD2;
    color: #C63737;
  }

  &.status-lowstock {
    background: #FEEDAF;
    color: #8A5340;
  }
}

.order-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;

  &.order-delivered {
    background: #C8E6C9;
    color: #256029;
  }

  &.order-cancelled {
    background: #FFCDD2;
    color: #C63737;
  }

  &.order-pending {
    background: #FEEDAF;
    color: #8A5340;
  }

  &.order-returned {
    background: #ECCFFF;
    color: #694382;
  }
}
</style>

<style scoped>
.p-avatar.p-avatar-success {
    background-color: #C5E1A5;
    color: #121212;
}

.p-avatar.p-tag-danger {
    background-color: #F48FB1;
    color: #121212;
}

.soluk {
    color: #b99ca9;
    font-size: 15px;
}

.normalText {
    color: #FFFFFF;
}

hr.grad {
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(199, 21, 133, 0.75), rgba(199, 21, 133, 0), rgba(199, 21, 133, 0.75));
}

.p-datatable-tbody tr {
    border: 3px solid #304562;
    border-width: 3px 0 0 0;
}

.word-break {
    word-break: break-word;
}

.floatlabel-demo {
    position: fixed;
    top: 0; /* Position the navbar at the top of the page */
    width: 100%; /* Full width */
    overflow: hidden;
    margin-top: 75px;
    z-index: 9999;
}

.reviews {
    margin-top: 163px
}


</style>

